.Home {
  text-align: center;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#gallery {
  line-height:0;
  -webkit-column-count:5; /* split it into 5 columns */
  -webkit-column-gap:5px; /* give it a 5px gap between columns */
  -moz-column-count:5;
  -moz-column-gap:5px;
  column-count:4;
  column-gap:5px;
}

#gallery img:hover {
  filter:none;
}

#gallery img {
  width: 100% !important;
  height: auto !important;
  margin-bottom:5px; /* to match column gap */
  filter: grayscale(100%);
  transition: filter 2s;
}

@media (max-width: 1200px) {
  #gallery {
   -moz-column-count:    4;
   -webkit-column-count: 4;
   column-count:         4;
  }
}

@media (max-width: 1000px) {
  #gallery {
   -moz-column-count:    3;
   -webkit-column-count: 3;
   column-count:         3;
  }
}

@media (max-width: 800px) {
  #gallery {
   -moz-column-count:    2;
   -webkit-column-count: 2;
   column-count:         2;
  }
}

@media (max-width: 400px) {
  #gallery {
   -moz-column-count:    1;
   -webkit-column-count: 1;
   column-count:         1;
  }
}